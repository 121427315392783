<template>
  <div class="w-full pt-5 pb-5">
    <div v-if="hasModuleAccess">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
            class="ml-2"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Appraisal Scorecard
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template v-if="isLoading">
      <div style="height: 100%" class="mt-20">
        <loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template>
        <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
          <template>
            <div class="flex justify-between items-center">
              <span
                class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
              >
                {{ agreementData.title }}
              </span>
            </div>
          </template>
          <template>
            <div
              class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
            >
              <div
                class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                @click="handleShowDetails"
              >
                <span
                  class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                >
                  Performance Details
                </span>
                <span
                  class="w-9/12 border-t border-solid border-romanSilver h-0"
                />
                <Icon
                  :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                  class-name="text-darkPurple mx-auto self-center"
                  size="xms"
                />
              </div>
              <div
                class="flex flex-col gap-2"
                :class="{ hidden: !showDetails }"
              >
                <div class="flex p-3 shadow rounded-md gap-2">
                  <Icon
                    size="s"
                    icon-name="doc-cycle"
                    class-name="text-romanSilver self-start pt-1"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                    >
                      {{ agreementData.cycle }}
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{ agreementData.description }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                  >
                    <Icon
                      size="xs"
                      icon-name="calendar"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                      >
                        Period
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.start),
                            "MMMM dd, yyyy"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.end),
                            "MMMM dd, yyyy"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                    v-for="(cycle, index) in agreementCycles"
                    :key="index"
                    :class="cycle.active ? '' : 'opacity-40 cursor-not-allowed'"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-1"
                      size="xs"
                      icon-name="reviewicon"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                      >
                        {{ cycle.name }}
                      </span>
                      <span
                        class="font-bold text-sm flex leading-6 tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                        }}
                        -
                        {{ $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy") }}
                        <span v-if="extendedCycle">
                          <span
                            class="ml-4 flex"
                            v-if="extendedCycle.appraisalCycleId === cycle.id"
                          >
                            <p
                              class="text-sm uppercase text-mediumSeaGreen italic"
                              style="margin-top: 0.15rem"
                            >
                              Extended
                            </p>
                            <Icon
                              class="ml-3"
                              icon-name="icon-info"
                              size="xs"
                              style="margin-top: -0.15rem"
                            />
                          </span>
                        </span>
                        <span
                          class="font-bold ml-6 text-xs leading-6 flex tracking-wide text-mediumSeaGreen uppercase flex cursor-pointer"
                          @click="viewCycle(cycle.id)"
                        >
                          View
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template>
        <div class="w-full px-5">
          <div class="w-full">
            <Card class="p-5 pt-0">
              <CardFooter
                reloadcard
                @searchResult="handleSearch($event)"
                showcalendar
                @reload="reload"
              >
                <template v-slot:leftItems>
                  <!--- <Select
                  placeholder="Direct Reports"
                  :options="['Direct Reports', 'All My Reports']"
                /> -->
                </template>
              </CardFooter>
            </Card>
          </div>
          <div style="height: 100%" v-if="isLoading">
            <Loader size="xxs" :loader-image="false" />
          </div>
          <div v-else>
            <Table
              :headers="AppHeader"
              :items="items"
              v-if="items.length > 0 || loadingTable"
              aria-label="advance Table"
              class="w-full"
              :has-checkbox="false"
              :has-number="true"
              :pagination-list="metaData"
              :page-sync="true"
              @page="handlePage($event)"
              @itemsPerPage="handleItemsPerPage($event)"
              :loading="loadingTable"
            >
              <template v-slot:item="{ item }">
                <div
                  v-if="item.photo"
                  class="flex justify-start items-center gap-5"
                >
                  <div class="bg-mediumSeaGreen w-2 h-2 rounded-full" />
                  <div>
                    <img
                      class="mr-3"
                      :src="item.data.photo"
                      v-if="item.data.photo"
                      alt="user photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 30px; width: 30px; border-radius: 5px"
                      class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.data.appraisee}`) }}
                    </div>
                  </div>
                </div>

                <div v-if="item.appraisee">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.appraisee }}
                  </span>
                </div>
                <div v-if="item.designation">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.designation }}
                  </span>
                </div>
                <div v-if="item.jobLevel">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.jobLevel }}
                  </span>
                </div>
                <div v-if="item.functions">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.functions }}
                  </span>
                </div>
                <div v-if="item.appraisalScore">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.appraisalScore }}
                  </span>
                </div>
                <div v-if="item.appraisalDate">
                  <span
                    class="font-normal text-sm leading-6 tracking-wide text-jet capitalize"
                  >
                    {{
                      item.data.appraisalDate
                        ? $DATEFORMAT(
                            new Date(item.data.appraisalDate),
                            "MMMM dd, yyyy"
                          )
                        : "---"
                    }}
                  </span>
                </div>
              </template>
            </Table>
            <template>
              <div class="flex justify-end gap-2 py-5 uppercase">
                <div
                  class="w-60 flex flex-col justify-center items-center border border-romanSilver rounded-md py-2"
                >
                  <span
                    class="font-semibold text-base leading-5 text-darkPurple"
                    >{{ averageScore }}</span
                  >
                  <span class="font-semibold text-xs leading-5text-romainSilver"
                    >average score</span
                  >
                </div>
                <div
                  class="w-60 flex flex-col justify-center items-center border border-romanSilver rounded-md py-2"
                >
                  <span
                    class="font-semibold text-base leading-5 text-darkPurple"
                    >{{ myScore }}</span
                  >
                  <span class="font-semibold text-xs leading-5text-romainSilver"
                    >my score</span
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
    </div>
        <div v-else>
      <ErrorComponent />
    </div>

  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
// import Select from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import CardFooter from "@/components/CardFooter";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "OrganisationScorecard",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    BackButton,
    Breadcrumb,
    ErrorComponent,
    Table,
    // Select,
    Card,
    CardFooter,
  },
  data() {
    return {
      isLoading: false,
      showDetails: true,
      loadingTable: true,
      hasModuleAccess: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisal",
          href: "Performance Appraisal",
          id: "PerformanceAppraisal",
        },
        {
          disabled: false,
          text: "Appraisal Scorecard",
          href: "appraisal-scorecard",
          id: "OrganisationScorecard",
        },
      ],
      AppHeader: [
        { title: "", value: "photo", width: "6" },
        { title: "Name", value: "appraisee" },
        { title: "Designation", value: "designation" },
        { title: "Job Level", value: "jobLevel" },
        { title: "Function", value: "functions" },
        { title: "Appraisal Score", value: "appraisalScore" },
        { title: "Appraisal Date", value: "appraisalDate" },
      ],
      items: [],
      activeCycle: "",
      myScore: "",
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      templatesArr: [],
      cyclesArr: [],
      agreementCycles: [],
      averageScore: "",
      extendedCycle: {},
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.tableData = [];
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.tableData = [];
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getScoreCard(`${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getScoreCard(this.searchQuery);
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    reload() {
      this.loadingTable = true;
      this.items = [];
      this.getScoreCard();
    },

    getTemplate() {
      this.$_getPerfomanceTemplate("").then((response) => {
        const templatesArr = response.data.PerformanceTemplate;
        this.templateArr = templatesArr;
      });
    },

    cycleName(templateId) {
      let name;
      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          name = template.agreement_title;
        }
        return {};
      });
      return name || "";
    },

    getAppraisalCycle() {
      const today = new Date();

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const { extension } = response.data;

        this.extendedCycle = extension;

        this.agreementData.description = appraisal[0].description;

        if (appraisal[0].cycles.length > 0) {
          const appraisalCycle = appraisal[0].cycles.sort(
            (a, b) =>
              this.setDates(a.appraisal_starts) -
              this.setDates(b.appraisal_starts)
          );

          appraisalCycle.forEach((cycle) => {
            this.cyclesArr.push(cycle.id);

            const cycleStartDate = new Date(
              this.formatDateTime(cycle.appraisal_starts)
            );

            const cycleEndDate = new Date(
              this.formatDateTime(cycle.appraisal_ends)
            );

            this.agreementCycles.push({
              name: cycle.name,
              start: this.formatDateTime(cycle.appraisal_starts),
              end: this.formatDateTime(cycle.appraisal_ends),
              active: today >= cycleStartDate && today <= cycleEndDate,
              id: cycle.id,
            });

            const cycleData = this.agreementCycles.find(
              (cycles) => cycles.active
            );

            if (cycleData) {
              this.activeCycle = cycleData.id;
            } else {
              this.activeCycle = this.agreementCycles[0].id;
            }
          });
        }
        this.getAgreements();
      });
    },

    getAgreements() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;
        result.forEach((info) => {
          if (info.agreementDetail.year === Number(this.$route.params.year)) {
            this.agreementData.title = this.cycleName(
              info.agreementDetail.templateId
            );
            this.agreementData.cycle = this.cycleName(
              info.agreementDetail.templateId
            );
            this.agreementData.period.start = this.formatDateTime(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .start
            );
            this.agreementData.period.end = this.formatDateTime(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .end
            );
          }
        });
      });
      this.getScoreCard("");
    },

    getScoreCard(params) {
      this.items = [];
      const query = `?cycleId=${this.activeCycle}${params || ''}`;

      this.$_orgScoreCard(query)
        .then((response) => {
          this.averageScore = response.data.average;
          this.metaData = response.data.meta;
          this.myScore = response.data.myScore ? response.data.myScore : "---";

          const appraisals = response.data.employees;

          appraisals.map((appraisal) => {
            this.items.push({
              appraisee: `${appraisal.user.fname} ${appraisal.user.lname}`,
              designation: appraisal.user.designation ? appraisal.user.designation : '---',
              jobLevel: appraisal.user.level ? appraisal.user.level : '---',
              functions: appraisal.user.function ? appraisal.user.function : '---',
              appraisalScore: this.getAppraisalScore(appraisal),
              appraisalDate: this.getAppraisalDate(appraisal),
              photo: appraisal.user.photo,
              id: true,
            });
            return {};
          });

          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    getAppraisalScore(value) {
      let returnedData;

        if (value.reviewType === "mid_review") {
          returnedData = value.flag;
        } else {
          returnedData = value.appraisalScore;
        }
      return returnedData || "---";
    },

    getAppraisalDate(value) {
      const returnedData = value.dateSubmitted;
      return returnedData;
    },

    viewCycle(id) {
      this.activeCycle = id;
      this.loadingTable = true;
      this.getScoreCard();
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAppraisals",
        "viewOrganizationScorecard"
      );
      this.hasModuleAccess = true;
      this.getAppraisalCycle();
      this.getTemplate();
    } catch (error) {
      this.hasModuleAccess = false;
          }
  },
};
</script>
